<template>
  <div>
    <!-- start:: form wizard -->

      <div
        id="creation-spec-set"
        class="fixed_margin"
        style="font-size: 14px"
      >
        <!-- start: step 1 -->
        <div v-if="step == 1">
          <div class="row">
            <div class="col-12 mx-0">
              <validation-observer
                ref="spec_item_name"
                tag="form"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Spec Set Name"
                  rules="required"
                >

                  <label for="name">Spec set name:</label>
                  <b-form-input v-model="spec_set_name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </div>
          </div>
          <!-- end:: step 1 -->
        </div>
        <!-- start:: step 2 -->
        <div v-if="step == 2">
          <div
            v-if="specification_type == 'reuse'"
            class="my-2"
          >
            <div class="row  p-0 m-0">
              <div class="col-12  mx-0">
                <label for="select">Select Budget Group</label>
                <v-select
                  v-model="existing_item_budget_group"
                  :clearable="false"
                  :options="budget_group"
                  label="name"
                  :reduce="(option) => option.key"
                  @input="clearSelects"
                />
              </div>
              <div class="col-10">
                <label for="select">Select Spec</label>
                <v-select
                  v-model="existing_selected_item"
                  :clearable="false"
                  :options="filtered_specification_items"
                  label="name"
                >
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.name }} - ({{ option.element.id?option.element.element_name:option.construction_work.construction_spec }})
                  </template>
                  <template #option="option">
                    {{ option.name }} - ({{ option.element.id?option.element.element_name:option.construction_work.construction_spec }})
                  </template>
                </v-select>
              </div>
              <div class="col">
                <b-button
                  variant="outline-primary"
                  class="text-primary my-2 w-100"
                  @click="addSpecificationItem"
                >Add</b-button>
              </div>
              <div
                v-if="existing_selected_item != null"
                class="col"
              >
                <div class="row mx-0">
                  <div class="col-4" />
                  <div class="col-4">
                    <b>Base Spec</b>
                  </div>
                  <div class="col-4">
                    <b>Spec Data</b>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <b>Manufacturer</b>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <p v-if="existing_item_budget_group != 'CNST'">
                      {{ item_attached_to_spec_item.vendor }}
                    </p>
                    <p
                      v-else
                      class="text-muted"
                    >
                      --
                    </p>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <p v-if="existing_item_budget_group != 'CNST'">
                      {{ existing_selected_item.manufacturer }}
                    </p>
                    <p
                      v-else
                      class="text-muted"
                    >
                      --
                    </p>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                   <b> Markup</b>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    {{ Number(((existing_selected_item.element.supply_price-existing_selected_item.element.unit_cost)/(existing_selected_item.element.unit_cost))*100).toFixed(2) }} %
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <p v-if="existing_item_budget_group !== 'CNST'">
                      {{ existing_selected_item.markup }} %
                    </p>
                    <p
                      v-if="existing_item_budget_group === 'CNST'"
                      class="text-muted"
                    >
                      --
                    </p>
                  </div>
                  <div class="col-4">
                    <b>Unit COGS</b>
                  </div>
                  <div class="col-4">
                    <p  v-if="existing_item_budget_group != 'CNST'">
                      {{
                        Number(item_attached_to_spec_item.unit_cost).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                    </p>
                    <p v-else>
                      {{
                        Number(existing_selected_item.construction_work.unit_price).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4" >
                    <p >
                      {{
                        Number(existing_selected_item.cogs_per_unit).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                    <b>Unit Price</b>
                  </div>
                  <div class="col-4">
                    <p v-if="existing_item_budget_group !== 'CNST'">
                      {{
                        Number(item_attached_to_spec_item.supply_price).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                    </p>
                    <p v-else>
                      {{
                        Number(existing_selected_item.construction_work.unit_price).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{
                        Number(existing_selected_item.price_per_unit).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    D&I
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <p v-if="existing_item_budget_group !== 'CNST'">
                      {{
                        Number(
                          item_attached_to_spec_item.unit_delivery_w_install
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </p>
                    <p
                      v-else
                      class="text-muted"
                    >

                    </p>
                  </div>
                  <div class="col-4" v-if="existing_item_budget_group != 'CNST'">
                    <p v-if="existing_item_budget_group !== 'CNST'">
                      {{
                        Number((existing_selected_item.price_per_unit)*(existing_selected_item.unit_delivery_install/100)).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                      ({{ existing_selected_item.unit_delivery_install }} %)
                    </p>

                    <p
                      v-else
                      class="text-muted"
                    >

                    </p>
                  </div>
                  <div class="col-4">
                    <b>Total Unit Price</b>
                  </div>
                  <div class="col-4">
                    <p v-if="existing_item_budget_group !== 'CNST'">
                      {{
                        Number(item_attached_to_spec_item.total_price).toLocaleString(
                          "en-CA",
                          {
                            minimumFractionDigits: 2,
                            style: "currency",
                            currency: "CAD",
                          }
                        )
                      }}
                    </p>
                    <p v-else>
                      {{
                        Number(existing_selected_item.construction_work.unit_price).toLocaleString(
                            "en-CA",
                            {
                              minimumFractionDigits: 2,
                              style: "currency",
                              currency: "CAD",
                            }
                        )
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                    <p>
                      {{
                        Number(
                          parseFloat(existing_selected_item.price_per_unit*(1+existing_selected_item.unit_delivery_install/100))
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </p>
                  </div>
                  <div class="col-4">
                  <b>Other Profit Unit </b>
                </div>
                  <div class="col-4">
                  </div>
                  <div class="col-4">
                    <p>
                      {{
                        Number(
                            parseFloat(existing_selected_item.rebate_unit)
                        ).toLocaleString("en-CA", {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        })
                      }}
                    </p>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end:: step 2 -->
        <!-- start:: step 3 -->
        <div v-if="step == 3" class=" p-0 m-0">
          <h5>Spec Set {{ spec_set_name }} Summary</h5>
          <div v-if="items.filter((i) => i.budget_group == 'CNST').length > 0">
            <label
              for=""
              class="bold"
            > Construction Spec</label>
            <b-table
              striped
              hover
              :items="items.filter((i) => i.budget_group == 'CNST')"
              :fields="fields"
            >
              <template #cell(name)="data">
                {{ data.item.name }} - ({{ data.item.construction_work.construction_spec }})
              </template>
              <template #cell(price_per_unit)="data">
                {{
                  Number(data.item.price_per_unit).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template #cell(actions)="data">
                <div class="w-100 text-center">
                  <a @click="removeItemFromSummary(data.item)">
                    <uil-times style="width: 20px; height: 20px" />
                  </a>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="items.filter((i) => i.budget_group == 'FF&E').length > 0">
            <label
              for=""
              class="bold"
            > FF&E Spec</label>
            <b-table
              striped
              hover
              :items="items.filter((i) => i.budget_group == 'FF&E')"
              :fields="fields"
            >
              <template #cell(name)="data">
                {{ data.item.name }} - ({{ data.item.element.specification_key }})
              </template>
              <template #cell(price_per_unit)="data">
                {{
                  Number(data.item.price_per_unit).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template #cell(actions)="data">
                <div class="w-100 text-center">
                  <a @click="removeItemFromSummary(data.item)">
                    <uil-times style="width: 20px; height: 20px" />
                  </a>
                </div> </template></b-table>
          </div>
          <div v-if="items.filter((i) => i.budget_group == 'AVIT').length > 0">
            <label
              for=""
              class="bold"
            > AV/IT Spec</label>
            <b-table
              striped
              hover
              :items="items.filter((i) => i.budget_group == 'AVIT')"
              :fields="fields"
            >
              <template #cell(name)="data">
                {{ data.item.name }} - ({{ data.item.element.specification_key }})
              </template>
              <template #cell(price_per_unit)="data">
                {{
                  Number(data.item.price_per_unit).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </template>
              <template #cell(actions)="data">
                <div class="w-100 text-center">
                  <a @click="removeItemFromSummary(data.item)">
                    <uil-times style="width: 20px; height: 20px" />
                  </a>
                </div> </template></b-table>
          </div>
        </div>
        <!-- end:: step 3 -->
        <div class="text-right my-2  p-0 mx-0">
          <b-button
            v-if="step != 1"
            variant="secondary"
            class="mx-1"
            @click="step--"
          >Go back</b-button>
          <b-button
            v-if="step == 1"
            variant="primary"
            @click="goToNextStep"
          >Define Specs Items</b-button>
          <b-button
            v-if="step == 2"
            variant="primary"
            :disabled="items.length == 0"
            @click="step++"
          >Review Specs Items</b-button><b-button
            v-if="step == 3"
            variant="primary"
            @click="saveItems"
          >
            Save
          </b-button>
        </div>
      </div>
    <!-- end:: form wizard -->
  </div>
</template>

<script>
import {
  BOverlay,
  BFormInput,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
  BInputGroup,
  BTable,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilTimes, UilGripHorizontalLine } from '@iconscout/vue-unicons'

import { required } from '@core/utils/validations/validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    BOverlay,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BTable,
    BFormRadio,
    required,
    ValidationObserver,
  },
  data() {
    return {
      show: false,
      step: 1,
      budget_group: [
        { name: 'Construction', key: 'CNST' },
        { name: 'FF&E', key: 'FF&E' },
        { name: 'AV/IT', key: 'AVIT' },
      ],
      specification_type: 'reuse',
      form: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: null,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      emptyForm: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: null,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      spec_set_name: '',
      existing_spectifications_items: [],
      selected_item: null,
      existing_selected_item: null,
      existing_item_budget_group: 'CNST',
      avit: [],
      cnst: [],
      ffe: [],
      fields: [
        {
          key: 'name',
          label: 'Spec',
          sortable: true,
        },
        {
          key: 'price_per_unit',
          label: 'Unit Price w/D&I',
          sortable: false,
        },
        {
          key: 'actions',
          label: 'Remove Spec',
          sortable: true,
        },
      ],
      items: [],
      existing_spectifications_items_no_filter: [],
    }
  },
  computed: {
    filtered_specification_items() {
      this.existing_spectifications_items = this.existing_spectifications_items_no_filter
      if (this.items.length > 0) {
        this.items.map(i => {
          if (i.budget_group === 'CNST') {
            this.existing_spectifications_items = this.existing_spectifications_items.filter(it => it.construction_work_id !== i.construction_work_id)
          } else {
            this.existing_spectifications_items = this.existing_spectifications_items.filter(it => it.uid !== i.uid)
          }
        })
      }
      if (this.existing_item_budget_group != null) {
        switch (this.existing_item_budget_group) {
          case 'CNST':
            return this.existing_spectifications_items.filter(
              e => e.budget_group == 'CNST',
            )
          case 'FF&E':
            return this.existing_spectifications_items.filter(
              e => e.budget_group == 'FF&E',
            )
          default:
            return this.existing_spectifications_items.filter(
              e => e.budget_group == 'AVIT',
            )
        }
      } else {
        return []
      }
    },
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + Number(this.form.markup) / 100),
      )
    },
    total_price_w_delivery() {
      if (this.form.budget_group != 'CNST') {
        return Number(this.total_price * (1 + (this.form.unit_delivery_install / 100)))
      }
      return this.form.price_per_unit
    },

    item_attached_to_spec_item() {
      const empty = {
        id: 0,
        name: null,
        specification_swap_set_id: 0,
        budget_group: '',
        uid: null,
        construction_work_id: 0,
        unit_delivery_install: 0,
        markup: 0,
        cogs_per_unit: 0,
        price_per_unit: 0,
        manufacturer: '',
      }
      if (this.existing_selected_item != null) {
        if (this.existing_selected_item.uid == null) {
          return this.cnst.find(
            c => c.id == this.existing_selected_item.construction_work_id,
          )
        }
        let result = empty
        if (this.existing_item_budget_group == 'FF&E') {
          result = this.ffe.find(c => c.uid == this.existing_selected_item.uid)
        }
        if (this.existing_item_budget_group == 'AVIT') {
          result = this.avit.find(c => c.uid == this.existing_selected_item.uid)
        }
        return result
      }
      return empty
    },
  },
  mounted() {
    this.getBaseSpecItems()
    this.getExistingSpecItems()
  },
  methods: {
    getExistingSpecItems() {
      this.$http
        .get('/specifications/items')
        .then(response => {
          const { data } = response
          this.existing_spectifications_items = data
          this.existing_spectifications_items_no_filter = data
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    getBaseSpecItems() {
      this.$http
        .get('/specifications/sets/base-spec')
        .then(response => {
          const { data } = response
          this.avit = data.avit
          this.cnst = data.cnst
          this.ffe = data.ffe
        })
        .catch(error => {
          this.show = false
          this.showToast('danger', error.response.data.message)
        })
    },
    addExistingSpecification() {
      this.form.specifications.push(this.existing_selected_item)
      this.existing_selected_item = null
      this.showToast('success', 'Spec added')
    },
    clearSelects() {
      (this.existing_selected_item = null), (this.selected_item = null)
    },
    addSpecificationItem() {
      if (this.specification_type == 'new') {
        this.form.idx = `id${Math.random().toString(20).slice(2)}`
        this.form.id = null
        if (this.form.budget_group != 'CNST') {
          this.form.price_per_unit = this.total_price_w_delivery
          this.form.uid = this.selected_item.uid
          this.form.construction_work_id = null
        } else {
          this.form.construction_work_id = this.selected_item.id
          this.form.uid = null
          this.form.unit_delivery_install = 0
          this.form.markup = 0
        }
        this.items.push(this.form)
        this.form = { ...this.emptyForm }
        this.selected_item = null
        this.showToast('success', 'Spec added')
      } else {
        this.existing_selected_item.idx = `id${Math.random().toString(20).slice(2)}`
        this.items.push(this.existing_selected_item)
        this.existing_selected_item = null
        this.showToast('success', 'Spec added')
      }
    },
    removeItemFromSummary(item) {
      if (this.items.length > 1) {
        this.items = this.items.filter(i => i.idx != item.idx)
      }
      this.filtered_specification_items
    },
    saveItems() {
      this.$emit('save', {
        name: this.spec_set_name,
        status: 'Unpublished',
        items: this.items,
      })
      this.step = 1
      this.spec_set_name = ''
      this.items = []
      this.form = { ...this.emptyForm }
      this.clearSelects()
    },
    goToNextStep() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_name.validate().then(success => {
          if (success) {
            this.step = 2
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
label {
  font-size: 14px;
}
.fixed_margin{
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 15px;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
