<template>
  <div>
    <app-timeline>
      <template v-for="activityLog in activity_logs">
        <!-- <app-timeline-item
                :key="activityLog.id"
                :title="activityLog.description + ' by ' + activityLog.responsible"
                time="few minutes ago"
                :variant="activityLog.color_variant"
                /> -->

        <app-timeline-item :variant="activityLog.color_variant" :key="activityLog.id">
          <div
            class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            style="font-size: 14px"
          >
            <h6 style="font-size: 14px">
              {{ activityLog.description }} by {{ activityLog.responsible }}
            </h6>
          </div>
          <div
            class="d-flex flex-sm-row flex-column justify-content-between align-items-start"
          >
            <div class="mb-1 mb-sm-0">
              <div></div>
              <small  style="color:#6e6b7b; font-size: 12px;" class="text-muted" v-if="aDayAgo(activityLog.created_at) == 0">{{
                relativeTime(activityLog.created_at)
              }}</small>
              <small  style="color:#6e6b7b; font-size: 12px;" v-else>{{ formattedDate(activityLog.created_at) }}</small>
            </div>
          </div>
        </app-timeline-item>
      </template>
    </app-timeline>
  </div>
</template>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BTooltip,
  BButton,
  BCollapse,
  BListGroup,
  BListGroupItem,
  VBToggle,
  VBTooltip,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { UilInfoCircle } from "@iconscout/vue-unicons";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "ReviewConstructionSpecSection",
  data() {
    return {};
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    vSelect,
    UilInfoCircle,
    BTooltip,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    VBTooltip,
    BAvatar,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  props: ["activity_logs"],
  methods: {
    initials(text) {
      const initial = text
        .match(/(\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();

      return initial;
    },
    relativeTime(date) {
      return this.$dateF.formatDate(date);
    },
    aDayAgo(date) {
      var a = moment();
      var b = moment(date);
      return a.diff(b, "days");
    },
    formattedDate(date) {
      return this.$dateF.formatDate(date);
    },
  },
};
</script>

<style scoped>
.label-exclusions {
  margin: auto;
}

.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}

.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}

.cell-styling {
  height: 33px;
}

.cell-styling-double {
  height: 65px;
}
</style>
