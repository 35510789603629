var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-1"},[_c('p',{staticClass:"mt-3",staticStyle:{"font-size":"28px","font-weight":"700"}},[_vm._v(" Spec Catalogue ")]),_vm._m(0),_c('b-tabs',[_c('b-tab',{attrs:{"active":"","title":"Sets"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex justify-content-end mr-2 mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_spec_set),expression:"search_spec_set"}],staticClass:"form form-control",staticStyle:{"width":"250px"},attrs:{"type":"text mr-1","placeholder":"Search"},domProps:{"value":(_vm.search_spec_set)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search_spec_set=$event.target.value}}}),_c('button',{staticClass:"btn btn-primary ml-2",on:{"click":function($event){_vm.display_create_modal = true}}},[_vm._v(" Add New Spec Set ")])]),_c('v-data-table',{staticClass:"elevation-1 m-2",attrs:{"headers":_vm.headers_spec_sets,"items":_vm.specification_sets,"sort-by":"created_at","search":_vm.search_spec_set,"items-per-page":_vm.items_per_page},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ml-2"},[_c('a',{on:{"click":function($event){return _vm.goToEdit(item.id)}}},[_c('v-icon',[_vm._v(" mdi-eye")])],1),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteSpecSet(item)}}},[_vm._v(" mdi-trash-can ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDatetime(item.created_at))+" ")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDatetime(item.updated_at))+" ")])]}},{key:"no-data",fn:function(){return [_vm._v(" There are not Spec sets to show ")]},proxy:true}])})],1)]),(false)?_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"m-1"},[_c('h4',{staticClass:"ml-1 my-2"},[_vm._v(" Activity Log ")]),_c('LogCard',{attrs:{"activity_logs":_vm.fake_activity_logs}})],1)])]):_vm._e()])]),_c('b-tab',{attrs:{"title":"Specs"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"d-flex justify-content-end mr-2 mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search_spec_set_item),expression:"search_spec_set_item"}],staticClass:"form form-control",staticStyle:{"width":"250px"},attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.search_spec_set_item)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search_spec_set_item=$event.target.value}}}),_c('button',{staticClass:"btn btn-primary ml-2",on:{"click":_vm.openCreateSpecSetItem}},[_vm._v(" Add New Spec ")])]),_c('v-data-table',{staticClass:"elevation-1 m-2",attrs:{"headers":_vm.headers,"items":_vm.specification_items,"sort-by":"created_at","search":_vm.search_spec_set_item,"items-per-page":_vm.items_per_page},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:("header." + i),fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('div',{key:i,staticStyle:{"display":"inline-block","padding":"16px 0"}},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('div',{key:'filter' + i,staticStyle:{"float":"right","margin-top":"8px"},attrs:{"id":"attachId"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":400,"min-width":300,"bottom":"","right":"","attach":"#attachId"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{style:(_vm.activeFilters[header.value] &&
                                    _vm.activeFilters[header.value].length <
                                    _vm.filters[header.value].length
                                    ? 'color:black'
                                    : 'color:#dedede'),attrs:{"small":""}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_vm._l((_vm.filters[header.value]),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false}},[[_c('v-list-item-action',[_c('b-form-checkbox',{attrs:{"value":item},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"normal","text-transform":"none"}},[_vm._v(_vm._s(_vm.getBudgetName(item))+" ")])],1)]],2)]})],2)],1),_c('v-row',{staticStyle:{"background-color":"#fff"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(59, 171, 255, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.toggleAll(header.value)}}},[_vm._v("Select all")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(59, 171, 255, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.clearAll(header.value)}}},[_vm._v("Deselect all")])],1)],1)],1)],1)])]}}}),{key:"item.budget_group",fn:function(ref){
                                    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getBudgetName(item.budget_group))+" ")])]}},{key:"item.cogs_per_unit",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.price_per_unit).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}},{key:"item.element",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.budget_group=='CNST'? item.construction_work.construction_spec : item.element.element_name)+" ")]}},{key:"item.price_per_unit",fn:function(ref){
                                    var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.price_per_unit).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}},{key:"item.actions",fn:function(ref){
                                    var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openUpdateItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.openDeleteSpecItem(item)}}},[_vm._v(" mdi-trash-can ")])]}},{key:"no-data",fn:function(){return [_vm._v(" There are not Specs to show ")]},proxy:true}],null,true)})],1)]),(false)?_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"m-1"},[_c('h4',{staticClass:"ml-1 my-2"},[_vm._v(" Activity Log ")]),_c('LogCard',{attrs:{"activity_logs":_vm.fake_activity_logs}})],1)])]):_vm._e()])]),_c('b-tab',{attrs:{"title":"Defaults"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"m-3"},[_c('div',{staticClass:"d-flex justify-content-end my-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],staticClass:"form form-control",staticStyle:{"width":"250px"},attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}})]),_c('v-data-table',{attrs:{"headers":_vm.headersElement,"items":_vm.elements,"search":_vm.filter,"items-per-page":_vm.items_per_page},scopedSlots:_vm._u([_vm._l((_vm.filtersElement),function(col,i){return {key:("header." + i),fn:function(ref){
                                    var header = ref.header;
return [_c('div',{staticClass:"d-flex"},[_c('div',{key:i,staticStyle:{"display":"inline-block","padding":"16px 0"}},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('div',{key:'filterElements' + i,staticStyle:{"float":"right","margin-top":"8px"},attrs:{"id":"filterElementsId"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":400,"min-width":300,"bottom":"","right":"","attach":"#filterElementsId"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{style:(_vm.elementActiveFilters[header.value] &&
                                  _vm.elementActiveFilters[header.value].length <
                                  _vm.filtersElement[header.value].length
                                  ? 'color:black'
                                  : 'color:#dedede'),attrs:{"small":""}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0"},[_c('v-list-item-group',[_vm._l((_vm.filtersElement[header.value]),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false}},[[_c('v-list-item-action',[_c('b-form-checkbox',{attrs:{"value":item},model:{value:(_vm.elementActiveFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.elementActiveFilters, header.value, $$v)},expression:"elementActiveFilters[header.value]"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"font-weight":"normal","text-transform":"none"}},[_vm._v(_vm._s(item)+" ")])],1)]],2)]})],2)],1),_c('v-row',{staticStyle:{"background-color":"#fff"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(59, 171, 255, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.toggleAllElements(header.value)}}},[_vm._v("Select all")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('b-button',{staticStyle:{"border-color":"transparent","color":"rgba(59, 171, 255, 1)"},attrs:{"block":"","variant":"outline-light"},on:{"click":function($event){return _vm.clearAllElements(header.value)}}},[_vm._v("Deselect all")])],1)],1)],1)],1)])]}}}),{key:"item.unit_price",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.unit_price).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}},{key:"item.unit_delivery_w_install",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.unit_delivery_w_install).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}},{key:"item.unit_cost",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.unit_cost).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}},{key:"item.total_price",fn:function(ref){
                                  var item = ref.item;
return [_vm._v(" "+_vm._s(Number((1*item.unit_price)).toLocaleString("en-CA", { minimumFractionDigits: 2, style: "currency", currency: "CAD", }))+" ")]}}],null,true)})],1)])])],1)],1),_c('b-modal',{attrs:{"hide-footer":true,"no-close-on-backdrop":true,"title":"New Spec Set","size":"lg","centered":""},model:{value:(_vm.display_create_modal),callback:function ($$v) {_vm.display_create_modal=$$v},expression:"display_create_modal"}},[_c('form-wizard-catalogue-vue',{on:{"save":_vm.saveSpecificationSet}})],1),_c('b-modal',{attrs:{"hide-footer":true,"no-close-on-backdrop":true,"title":"New Spec","size":"lg","centered":""},model:{value:(_vm.display_create_item_modal),callback:function ($$v) {_vm.display_create_item_modal=$$v},expression:"display_create_item_modal"}},[_c('SpecSetItemForm',{attrs:{"spec-item":_vm.itemToSave,"base_items":_vm.base_spec_items},on:{"saveItem":_vm.saveSpecItem}})],1),_c('b-modal',{attrs:{"title":"Delete Spec","centered":""},on:{"ok":_vm.confirmDeleteSpecItem},model:{value:(_vm.display_delete_item_modal),callback:function ($$v) {_vm.display_delete_item_modal=$$v},expression:"display_delete_item_modal"}},[_c('h5',[_vm._v("This action will remove the spec, "),_c('b',[_vm._v("it will not be accessible in any future spec set or quote creation,")]),_vm._v(" this action cannot be reversed.")])]),_c('b-modal',{attrs:{"title":"Delete Spec Set","centered":""},on:{"ok":_vm.confirmDeleteSpecSet},model:{value:(_vm.display_delete_set_modal),callback:function ($$v) {_vm.display_delete_set_modal=$$v},expression:"display_delete_set_modal"}},[_c('h5',[_vm._v("This action will remove the spec set, "),_c('b',[_vm._v("it will no be accessible in any future quote creation, all previous quotes will not be affected")]),_vm._v(", this action cannot be reversed.")]),_c('p',[_vm._v(" The specs attached to it will not be affected. ")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex"})]),_c('div',{staticClass:"col-6 text-right"})])}]

export { render, staticRenderFns }